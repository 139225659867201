import { useCallback, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import type {
  Control,
  DeepMap,
  FieldError,
  FormState,
} from "react-hook-form/dist/types";
import type { FileRejection } from "react-dropzone";
import { useDropzone } from "react-dropzone";
import { UploadWrapper } from "../../../../../../components/FilePicker/FilePicker";
import {
  MAXIMUM_FILE_SIZE,
  SUPPORTED_DOC_TYPES,
  SUPPORTED_IMG_TYPES,
  SUPPORTED_VIDEO_TYPES,
} from "../../../../../../components/FilePicker/FilePicker.constants";
import { UploadedFileName } from "../../../../../../components/FileCard/FileCard";
import { SecondaryButtonSmall } from "../../../../../../components/Buttons/Buttons";
import { FormInputErrorText } from "../../../../../../components/Typography/Typography";
import { formatBytes, getObjectByPath } from "./AssetsUtil";

const ModifiedUploadWrapper = styled(UploadWrapper)`
  margin: 0px !important;
  word-wrap: break-word;
`;

const FileWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  column-gap: 5px;
`;

const DropHereMessage = styled.div`
  margin: 10px 0px;
`;

export const FileUploader = ({
  selectedFile,
  setSelectedFile,
  setValue,
  selectedAssetType,
  control,
  errors,
  formState,
  existingFileName,
  form_file_name = "file_type",
  form_asset_name = "asset_name",
}: {
  setValue: (name: string, value: unknown, config?: Object) => void;
  selectedAssetType: string;
  formState: FormState<Record<string, any>>;
  errors: DeepMap<Record<string, any>, FieldError>;
  control: Control;
  selectedFile: File | null;
  setSelectedFile: (file: File) => void;
  isReplaced?: boolean;
  existingFileName?: string;
  form_file_name?: string;
  form_asset_name?: string;
}) => {
  const { t } = useTranslation();

  const [allowedFileTypes, setAllowedFileTypes] = useState<string[] | null>();

  const [DropzoneError, setDropzoneError] =
    useState<FileRejection | string | null>();

  const parts = form_file_name.split(".");

  const formError = getObjectByPath(errors, parts)
    ? "This is a required field"
    : null;

  useEffect(() => {
    if (selectedAssetType === "document") {
      setAllowedFileTypes(SUPPORTED_DOC_TYPES);
    }
    if (selectedAssetType === "image") {
      setAllowedFileTypes(SUPPORTED_IMG_TYPES);
    }
    if (selectedAssetType === "video") {
      setAllowedFileTypes(SUPPORTED_VIDEO_TYPES);
    }
  }, [selectedAssetType]);

  const onDrop = useCallback(
    (files: File[], fileRejections) => {
      const errorCode = fileRejections[0]?.errors[0]?.code;
      const fileSize = (
        fileRejections[0]?.file?.size / Math.pow(10, 6)
      ).toFixed(2);

      if (errorCode) {
        if (errorCode === "file-too-large") {
          setDropzoneError(
            t(
              "Size of File is {{fileSize}} MB and should be less than {{maxFileSize}}",
              { fileSize, maxFileSize: formatBytes(MAXIMUM_FILE_SIZE) }
            )
          );
        }
        if (errorCode === "file-invalid-type") {
          setDropzoneError(t(`This file type is not allowed`));
        }
      } else {
        setSelectedFile(files[0]);
        setValue(form_asset_name, files[0]?.name, {
          shouldValidate: true,
        });
        setDropzoneError(null);
        setValue(form_file_name, files[0], {
          shouldValidate: true,
        });
      }
    },
    [t, setSelectedFile, setValue, form_asset_name, form_file_name]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: MAXIMUM_FILE_SIZE,
    accept: allowedFileTypes ?? [],
  });

  const fileName = selectedFile?.name ? selectedFile?.name : existingFileName;

  const hasError = DropzoneError ?? formError;

  return (
    <>
      <div>
        <Controller
          control={control}
          name={form_file_name}
          autoComplete={"file_type"}
          rules={{
            required: {
              value: true,
              message: t("This is a required field"),
            },
          }}
          defaultValue={selectedFile}
          errors={errors}
          formState={formState}
          render={({ onChange, onBlur }) => (
            <ModifiedUploadWrapper
              {...getRootProps()}
              error={DropzoneError || formError}
            >
              <input
                {...getInputProps({
                  onChange,
                  onBlur,
                })}
                data-testid="file-picker-input"
                disabled={!selectedAssetType}
              />
              {isDragActive ? (
                <p>{t("Drop the document here...")}</p>
              ) : (
                <FileWrapper>
                  {fileName ? (
                    <UploadedFileName fileName={fileName ?? ""} />
                  ) : (
                    <DropHereMessage>
                      {t("Drag and drop a file or")}
                    </DropHereMessage>
                  )}

                  {!selectedFile && !existingFileName ? (
                    <SecondaryButtonSmall
                      onClick={(e) => e.preventDefault()}
                      style={{ marginTop: "4px" }}
                    >
                      {t("Click to Upload")}
                    </SecondaryButtonSmall>
                  ) : (
                    <SecondaryButtonSmall
                      onClick={(e) => e.preventDefault()}
                      style={{ marginTop: "4px" }}
                    >
                      {t("Click to replace")}
                    </SecondaryButtonSmall>
                  )}
                  <DropHereMessage>
                    {t("Max file size: {{maxFileSize}}", {
                      maxFileSize: formatBytes(MAXIMUM_FILE_SIZE),
                    })}
                  </DropHereMessage>
                  {allowedFileTypes && (
                    <DropHereMessage>
                      {t("Allowed extensions")}{" "}
                      {`( ${allowedFileTypes?.join(", ")} )`}
                    </DropHereMessage>
                  )}
                </FileWrapper>
              )}
            </ModifiedUploadWrapper>
          )}
        />
        {hasError && <FormInputErrorText>{hasError}</FormInputErrorText>}
      </div>
    </>
  );
};
